<template>
  <div class="col-12 pl-10 pr-10 mb-20">
    <div class="card">
      <div class="card_header diff">
        <h5 class="card_title"><i class="ti ti-receipt mr-5"></i>결산내역</h5>
        <div class="tabMenu_inner border-0 tab_ctgy03">
          <ul class="tab_link_box">
            <li :class="{ active: selectTab === 0 }" @click="changeTab(0)">
              주간
            </li>
            <li :class="{ active: selectTab === 1 }" @click="changeTab(1)">
              월간
            </li>
          </ul>
        </div>
      </div>
      <div class="card_body">
        <div class="col-12 mb-15 search_group">
          <h5 class="item_name">
            {{ selectTab == 0 ? "주간 검색" : "월별 검색" }}
          </h5>
          <input
            v-if="selectTab == 0"
            type="date"
            class="form_control wp-20 mr-5 display-inline"
            v-model="logInfo.start_date"
          />
          <span class="mr-5" v-if="selectTab == 0">-</span>
          <input
            v-if="selectTab == 0"
            type="date"
            class="form_control wp-20 mr-5 display-inline"
            v-model="logInfo.end_date"
          />
          <input
            v-if="selectTab == 1"
            type="month"
            class="form_control wp-20 mr-5 display-inline"
            v-model="logInfo.date"
            @change="infoSearch"
          />
          <div class="filter-btn diff">
            <button
              v-if="selectTab == 0"
              type="button"
              class="btn btn-primary mlr-5"
              @click="infoSearch"
            >
              검색
            </button>
            <button
              @click="infoClear"
              type="button"
              class="btn btn-secondary mlr-5"
            >
              클리어
            </button>
          </div>
        </div>

        <div class="table-responsive text-nowrap dataTables_wrapper">
          <table class="table">
            <thead class="table-light">
              <tr>
                <th>월</th>
                <th v-if="selectTab == 0">주</th>
                <th>송신자</th>
                <th>송신일자</th>
                <th>수신일자</th>
                <th colspan="7">결산파일</th>
              </tr>
            </thead>
            <tbody v-if="isLoading">
              <td colspan="8" class="list_loading">
                <div class="loading_spinner">
                  <div class="loading_circle"></div>
                </div>
              </td>
            </tbody>
            <tbody v-if="monthLogList?.length == 0 && !isLoading">
              <tr>
                <td colspan="7">등록된 데이터가 없습니다.</td>
              </tr>
            </tbody>
            <tbody v-if="monthLogList?.length > 0 && !isLoading" >
              <tr v-for="data in monthLogList" :key="data.datetime">
                <td>{{ data.month }}월</td>
                <td v-if="selectTab == 0">{{ data.week }}</td>
                <td>{{ data.sender }}</td>
                <td>{{ data.send_time?.replace("T", " ").slice(0, 16) }}</td>
                <td>{{ data.recept_date }}</td>
                <td>
                  <a
                    :href="data.fileExcel"
                    :download="`${data.filename}.xls`"
                    class="excel_area"
                    >{{ `${data.filename}.xls` }}</a
                  >
                </td>
                <td>
                  <a
                    :href="data.filePdf"
                    :download="`${data.filename}.pdf`"
                    class="pdf_area"
                    >{{ `${data.filename}.pdf` }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <ThePaging
          v-if="totalPages > 1"
          :page="page"
          :changePage="changePage"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { autoLeftPad, preDate } from "@/utils/common";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
const store = useStore();
const fileExcel = computed(() => {
  return store.state.closing.fileExcel;
});
const filePdf = computed(() => {
  return store.state.closing.filePdf;
});
const monthLogList = computed(() => {
  return store.state.closing.monthLogList;
});
const page = computed(() => {
  return store.state.closing.page;
});
const totalPages = computed(() => {
  return store.state.closing.totalPages;
});
const selectTab = ref(0);
const date = new Date();
const prevDate = preDate(date, "month");
let isLoading = ref(false);
let logInfo = {
  date: `${date.getFullYear()}-${autoLeftPad(date.getMonth() + 1, 2)}`,
  type: selectTab.value,
  start_date: `${prevDate.getFullYear()}-${autoLeftPad(
    prevDate.getMonth() + 1,
    2
  )}-${autoLeftPad(prevDate.getDate(), 2)}`,
  end_date: `${date.getFullYear()}-${autoLeftPad(
    date.getMonth() + 1,
    2
  )}-${autoLeftPad(date.getDate(), 2)}`,
};

const infoSearch = async () => {
  store.commit("closing/setPage", 1);
  if (selectTab.value == 0) {
    let reqLogInfo = {
      date: `${logInfo.start_date}~${logInfo.end_date}` ?? "",
      type: logInfo.type,
    };
    console.log("reqLogInfo", reqLogInfo);
    store.commit("closing/setMonthLogInfo", reqLogInfo);
  } else {
    let reqLogInfo = {
      date: logInfo.date ?? "",
      type: logInfo.type,
    };
    console.log("reqLogInfo", reqLogInfo);
    store.commit("closing/setMonthLogInfo", reqLogInfo);
  }
  await store.dispatch("closing/getMonthLog");
  listFileAdd();
};
const infoClear = () => {
  createFn();
};
const listFileAdd = async () => {
  for (const obj of monthLogList.value) {
    if (selectTab.value == 0) {
      let datetime = `${obj.send_time.slice(0, 4)}${autoLeftPad(obj.week, 2)}`;
      obj.filename = `주간결산_${datetime}`;
      store.commit("closing/setLogWeekDate", datetime);
      await store.dispatch("closing/getWeekProfitExcel");
      await store.dispatch("closing/getWeekProfitPdf");
    } else {
      let datetime = obj.send_time.replaceAll("-", "").slice(0, 6);
      obj.filename = `월말결산_${datetime}`;
      store.commit("closing/setLogMonthDate", datetime);
      await store.dispatch("closing/getProfitExcel");
      await store.dispatch("closing/getProfitPdf");
    }
    obj.fileExcel = fileExcel.value;
    obj.filePdf = filePdf.value;
  }
};
const changePage = async (str) => {
  store.commit("closing/setPage", str);
  await store.dispatch("closing/getMonthLog");
};
const changeTab = async (num) => {
  selectTab.value = num;
  createFn();
};
const createFn = async () => {
  isLoading.value = true;
  let reqInfo = {
    type: 0,
    date: "",
  };
  store.commit("closing/setPage", 1);
  store.commit("closing/setMonthLogInfo", reqInfo);
  await store.dispatch("closing/getMonthLog");
  listFileAdd();
  isLoading.value = false;
};
createFn();
</script>
