<template>
  <div class="modal_overlay" @click.self="closeModal">
    <div class="modal_pop_small">
      <div class="smallPop popup_block_large">
        <div class="pop_title mt-20">
          <p>거래소별 수익금 현황</p>
        </div>
        <div class="con_wrap mt-20">
          <div class="table-responsive text-nowrap dataTables_wrapper">
            <table class="table">
              <thead class="table-light">
                <tr>
                  <th>거래소</th>
                  <th>금주 수익금</th>
                  <th>금월 수익금</th>
                  <th>총 수익금</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, i) in exchangeArr" :key="i">
                  <td>{{ data.name }}</td>
                  <td>{{ formatNum(data.weekly_profit,4) }}$</td>
                  <td>{{ formatNum(data.monthly_profit,4) }}$</td>
                  <td>{{ formatNum(data.total_profit,4) }}$</td>
                </tr>
                <tr class="bg_gray">
                  <td>전체</td>
                  <td>{{ formatNum(dashboardInfo.week_profit, 4) }}$</td>
                  <td>{{ formatNum(dashboardInfo.month_profit, 4) }}$</td>
                  <td>{{ formatNum(dashboardInfo.total_profit, 4) }}$</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="btn_bottom pt-0 pb-50">
          <button class="btn_gray" @click="closeModal">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import { formatNum } from "@/utils/common";
const store = useStore();
const dashboardInfo = computed(() => {
  return store.state.manage.dashboardInfo;
});
const monthCountInfo = computed(() => {
  return store.state.closing.monthCountInfo;
});
const weekCountInfo = computed(() => {
  return store.state.closing.weekCountInfo;
});
let exchangeArr = ref([
  { name: "OKX" },
  { name: "Toobit" },
  { name: "BingX" },
  { name: "Deepcoin" },
]);
const closeModal = () => {
  store.commit("admin/changeModalState", false);
};
const createdFn = async () => {
  for (const obj of exchangeArr.value) {
    switch (obj.name) {
      case "OKX":
        await store.dispatch("closing/getProfitMonth", 1);
        await store.dispatch("closing/getWeekExchange", 1);
        break;
      case "Toobit":
        await store.dispatch("closing/getProfitMonth", 3);
        await store.dispatch("closing/getWeekExchange", 3);
        break;
      case "BingX":
        await store.dispatch("closing/getProfitMonth", 2);
        await store.dispatch("closing/getWeekExchange", 2);
        break;
      case "Deepcoin":
        await store.dispatch("closing/getProfitMonth", 4);
        await store.dispatch("closing/getWeekExchange", 4);
        break;
    }
    obj.monthly_profit = monthCountInfo.value?.monthly_profit ?? "-";
    obj.total_profit = monthCountInfo.value?.total_accumulated_profit ?? "-";
    obj.weekly_profit = weekCountInfo.value?.weekly_profit ?? "-";
  }
};
createdFn();
</script>

<style lang="scss" scoped></style>
