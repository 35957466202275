<template>
  <!-- modal & popup  검정배경-->
  <div class="modalBackColor"></div>

  <div class="modal_overlay">
    <!-- [모달창]:start-->
    <div class="modal_wrap">
      <div class="modal_title">
        관리자 정보
        <div class="mdcolsebtn" @click="closeModal"></div>
      </div>
      <div class="modal_content">
        <!---->
        <div class="modal_body">
          <div class="form_inner">
            <div class="col-md-6 mb-15 pl-5 pr-5">
              <label class="form_label" for="">Admin ID</label>
              <input
                type="text"
                id="adminId"
                name="adminId"
                class="form_control"
                placeholder="admin ID"
                v-model="adminInfo.admin_id"
                :disabled="props.status == '1' ? false : true"
              />
            </div>
            <div class="col-md-6 mb-15 pl-5 pr-5" v-if="props.status == 1">
              <label class="form_label" for="">Password</label>
              <div class="input_group">
                <i class="pw_view" @click="blockPw"></i>
                <input
                  type="password"
                  id="adminPw"
                  name="adminPw"
                  class="form_control"
                  placeholder="············"
                  value=""
                  v-model="adminInfo.pwd"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-6 mb-15 pl-5 pr-5">
              <label class="form_label" for="">담당자</label>
              <input
                type="text"
                id="admin_Name"
                name="admin_Name"
                class="form_control"
                placeholder="담당자 이름"
                v-model="adminInfo.name"
              />
            </div>
            <div class="col-md-6 mb-15 pl-5 pr-5">
              <label class="form_label" for="">권한선택</label>
              <select
                id=""
                class="form-select wp-15 mr-5 wp-100"
                data-allow-clear="true"
                v-model="adminInfo.role"
                @change="changeColor"
              >
                <option value="" disabled hidden selected>권한 선택</option>
                <option value="1">전체 권한</option>
                <option value="2">일부 권한</option>
              </select>
            </div>
            <div class="col-md-6 mb-15 pl-5 pr-5" v-if="props.status === 1">
              <label class="form_label" for="">등록일</label>
              <input
                type="text"
                id="regi_date"
                name="regi_date"
                class="form_control"
                :value="adminInfo.datetime"
                disabled
              />
            </div>
            <div class="col-12 mb-15 pl-5 pr-5">
              <label class="form_label" for="">메모</label>
              <textarea
                class="form_control"
                id="admin_memo"
                name="admin_memo"
                rows="3"
                placeholder=""
                v-model="adminInfo.memo"
              ></textarea>
            </div>
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-primary mlr-5"
                @click="changeData"
              >
                확인
              </button>
              <button
                type="reset"
                class="btn btn-secondary mlr-5"
                @click="closeModal"
              >
                취소
              </button>
            </div>
          </div>
        </div>
        <!---->
      </div>
    </div>
    <!-- [모달창]:End -->
  </div>
  <ModalMsg
    :msg="msg"
    :callbackFn="closeModal"
    :callbackStatus="callbackStatus"
    v-if="modalState"
  />
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ModalMsg from "@/components/modal/ModalMsg.vue";
const store = useStore();
const props = defineProps({
  admin: { type: Object },
  status: { type: Number },
});
const modalState = computed(() => {
  return store.state.admin.modalState;
});
const fetchStatus = computed(() => {
  return store.state.admin.fetchStatus;
});
let adminInfo = ref({});
let msg = ref("");
let callbackStatus = 0;

onMounted(() => {
  if (props.status == 2) {
    adminInfo.value = props.admin;
  }
});
const changeData = async () => {
  if (
    adminInfo.value.admin_id == "" ||
    adminInfo.value.admin_id == null ||
    adminInfo.value.pwd == "" ||
    adminInfo.value.pwd == null ||
    adminInfo.value.name == "" ||
    adminInfo.value.name == null ||
    (adminInfo.value.role != 1 && adminInfo.value.role != 2)
  ) {
    msg.value = "누락된 항목이 없는지 확인해주세요.";
    callbackStatus = 0;
  } else {
    adminInfo.value.flag = props.status;
    store.commit("admin/setAdminInfo", adminInfo.value);
    if (props.status === 1) {
      await store.dispatch("admin/createAdmin");
    } else {
      store.commit("admin/setAdminId", props.admin.admin_id);
      await store.dispatch("admin/updateAdmin");
    }
    if (fetchStatus.value == 200) {
      msg.value = "성공적으로 등록되었습니다.";
      callbackStatus = 1;
      await store.dispatch("admin/getAdmin");
    } else if(fetchStatus.value == 400){
      msg.value = "중복된 아이디입니다. 다시 시도해주세요.";
      callbackStatus = 0;
    }else{
      msg.value = "등록에 실패하였습니다. 다시 시도해주세요.";
      callbackStatus = 0;
    }
  }
  store.commit("admin/changeModalState", true);
};
const closeModal = async () => {
  store.commit("admin/changeAdminModal", false);
  store.commit("admin/setFetchStatus", null);
};
</script>
