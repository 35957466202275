document.addEventListener("DOMContentLoaded", function () {
  // 관리자 브라우저 가로 크기 조정 - 사이드메뉴 보기/숨김
  const layoutMenu = document.querySelector(".layout_menu");
  const closeBtn = document.querySelector(".menu_close");
  const mainWrap = document.querySelector(".layout_page");
  // const navBtn = document.querySelector(".nav_btn");
  navChange();
  window.addEventListener("resize", function () {
    navChange();
  });

  //size마다 nav 다르게하기
  function navChange() {
    const screenWidth = window.innerWidth;
    if (layoutMenu) {
      if (screenWidth < 1199) {
        layoutMenu.classList.remove("active");
        closeBtn.classList.remove("dn");
        mainWrap.style.padding = "78px 0 0 0";
      } else {
        layoutMenu.classList.remove("active");
        closeBtn.classList.add("dn");
        mainWrap.style.padding = "78px 0 0 260px";
      }
    }
  }

  //햄버거 버튼 클릭 시 open nav

  //   navBtn &&
  //     navBtn.addEventListener("click", () => {
  //       layoutMenu.classList.add("active");
  //     });

  // 좌측 Main menu
  const menuItems = document.querySelectorAll(
    ".menu_item , .admin_infobox a, .logo_box a"
  );
  if (menuItems) {
    if (window.innerWidth < 1199) {
      menuItems.forEach((item) => {
        item.addEventListener("click", () => {
          document.querySelector(".layout_menu")?.classList.remove("active");
        });
      });
    }
  }

  // 사이드메뉴-닫기버튼,배경클릭시 -숨김
  const closBtn = document.querySelectorAll(".menu_close, .layout_overlay");
  if (closBtn) {
    closBtn.forEach(function (item) {
      item.addEventListener("click", function () {
        document.querySelector(".layout_menu").classList.remove("active");
      });
    });
  }

  // 사이드 메뉴 보기-버튼
  const navLinkView = document.querySelector(".nav_link_view");

  if (navLinkView) {
    navLinkView.addEventListener("click", function () {
      document.querySelector(".layout_menu").classList.remove("dn");
      document.querySelector(".layout_overlay").classList.add("view");
    });
  }
});
