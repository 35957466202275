<template>
  <div class="tabMenu_inner border-0 mt-0">
    <ul class="tab_link_box ctgy02">
      <li :class="{ active: exchange === '' }" @click="changeTab('')">ALL</li>
      <li :class="{ active: exchange === 1 }" @click="changeTab(1)">OKX</li>
      <li :class="{ active: exchange === 3 }" @click="changeTab(3)">Toobit</li>
      <li :class="{ active: exchange === 2 }" @click="changeTab(2)">BingX</li>
      <li :class="{ active: exchange === 4 }" @click="changeTab(4)">DeepCoin</li>
    </ul>
  </div>
  <div class="total_info diff mb-50">
    <div>
      <p class="fs-20">총 수익금</p>
      <p class="fs-30 text_blue">
        {{
          exchange == 0
            ? formatNum(dashboardInfo.total_profit, 4)
            : formatNum(monthCountInfo.total_accumulated_profit,4)
        }}$
      </p>
    </div>
    <div>
      <p class="fs-20">이번 달 수익금</p>
      <p class="fs-30 text_plus">
        {{
          exchange == 0
            ? formatNum(dashboardInfo.month_profit, 4)
            : formatNum(monthCountInfo.monthly_profit,4)
        }}$
      </p>
    </div>
  </div>
  <div class="card mb-20">
    <div class="card_header">
      <h5 class="card_title">
        <i class="ti ti-files mr-5"></i>월말결산
      </h5>
    </div>
    <div class="form_inner">
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">RETRI_IDX</h5>
        <input
          type="number"
          v-model="info.retri_id"
          class="form_control display-inline wp-36"
          placeholder="Search"
          @keyup.enter="infoSearch"
        />
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">거래소</h5>
        <select
          class="form-select display-inline wp-15 mr-5"
          data-allow-clear="true"
          v-model="info.exchange"
        >
          <option value="">All</option>
          <option value="okx">OKX</option>
          <option value="toobit">Toobit</option>
          <option value="bingx">BingX</option>
          <option value="deepcoin">DeepCoin</option>
        </select>
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">월별 검색</h5>
        <input
          type="month"
          class="form_control wp-20 mr-5 display-inline"
          v-model="info.date"
        />
      </div>
      <div class="filter-btn">
        <button type="button" class="btn btn-primary mlr-5" @click="infoSearch">
          검색
        </button>
        <button @click="infoClear" type="button" class="btn btn-secondary mlr-5">
          클리어
        </button>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="col-12 mb-20">
    <div class="card">
      <div class="table-responsive text-nowrap dataTables_wrapper">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>RETRI_IDX</th>
              <th v-if="info.exchange == '' && isAll">거래소</th>
              <th>UID</th>
              <th>수익금</th>
              <th>발생시간</th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-if="monthProfitList.length == 0">
              <td colspan="7">데이터 내역이 없습니다.</td>
            </tr>
            <tr v-for="(data, i) in monthProfitList" :key="i">
              <td>{{ data.retri_id }}</td>
              <td v-if="info.exchange == '' && isAll">
                {{ data.exchange }}
              </td>
              <td>{{ data.uid }}</td>
              <td>{{ formatNum(data.profit,4) }}</td>
              <td>
                {{
                  data.datetime == "0"
                    ? "지급예정"
                    : String(data.datetime).replaceAll("T", " ")
                }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <td colspan="8" class="list_loading">
              <div class="loading_spinner">
                <div class="loading_circle"></div>
              </div>
            </td>
          </tbody>
        </table>
        <ThePaging
          v-if="totalPages > 1"
          :page="page"
          :changePage="changePage"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { autoLeftPad, formatNum } from "@/utils/common";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
const store = useStore();
const dashboardInfo = computed(() => {
  return store.state.manage.dashboardInfo;
});
const monthProfitList = computed(() => {
  return store.state.closing.monthProfitList;
});
const monthCountInfo = computed(() => {
  return store.state.closing.monthCountInfo;
});
const page = computed(() => {
  return store.state.closing.page;
});
const totalPages = computed(() => {
  return store.state.closing.totalPages;
});
const isLoading = computed(() => {
  return store.state.closing.isLoading;
});
const date = new Date();
let exchange = ref("");
let isAll = true;
let info = {
  type : 1,
  date: `${date.getFullYear()}-${autoLeftPad(date.getMonth() + 1, 2)}`,
  retri_id: "",
  exchange: "",
};
const infoSearch = async () => {
  isAll = true;
  let reqInfo = {
    type : 1,
    date: info.date ?? "",
    retri_id: info.retri_id ?? "",
    exchange: info.exchange ?? "",
  };
  store.commit("closing/setPage", 1);
  store.commit("closing/setMonthInfo", reqInfo);
  await store.dispatch("closing/getUserProfit");
};
const infoClear = async () => {
  info = {
    type : 1,
    date: "",
    retri_id: "",
    exchange: "",
  };
  store.commit("closing/setMonthInfo", info);
  store.commit("closing/setPage", 1);
  await store.dispatch("closing/getUserProfit");
};
const changePage = async (str) => {
  store.commit("closing/setPage", str);
  await store.dispatch("closing/getUserProfit");
};
const changeTab = async (state) => {
  exchange.value = state;
  let exchangeName;
  switch (exchange.value) {
    case 1:
      exchangeName = "okx";
      break;
    case 2:
      exchangeName = "bingx";
      break;
    case 3:
      exchangeName = "toobit";
      break;
    case 4:
      exchangeName = "deepcoin";
      break;
  }
  let reqInfo = {
    type : 1,
    date: "",
    retri_id: "",
    exchange: exchangeName ?? "",
  };
  store.commit("closing/setPage", 1);
  store.commit("closing/setMonthInfo", reqInfo);
  if (exchange.value == 0) {
    isAll = true;
    await store.dispatch("manage/getDashBoard");
  } else {
    isAll = false;
    await store.dispatch("closing/getProfitMonth", exchange.value);
  }
  await store.dispatch("closing/getUserProfit");
};
const createFn = async () => {
  let reqInfo = {
    type : 1,
    date: "",
    retri_id: "",
    exchange: "",
  };
  store.commit("closing/setPage", 1);
  store.commit("closing/setMonthInfo", reqInfo);
  await store.dispatch("manage/getDashBoard");
  await store.dispatch("closing/getUserProfit");
};
createFn();
</script>
