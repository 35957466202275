import { API } from "./apiAuth";
let whileFetching = false;
let abortController;
// 월 수익율 pdf download api
const getProfitMonthApi = async (exchange, year_month) => {
  try {
    const response = await API.get(
      `monthly-profit-report?year_month=${year_month}&exchange=${exchange}`
    );
    return response.data;
  } catch (error) {
    return;
  }
};
// 월 수익율 pdf download api
const getProfitPdfApi = async (year_month) => {
  try {
    const response = await API.get(
      `monthly-profit/pdf?year_month=${year_month}`
    );
    return response;
  } catch (error) {
    return;
  }
};
// 월 수익율 excel download api
const getProfitExcelApi = async (year_month) => {
  try {
    const response = await API.get(
      `monthly-profit/excel-xls?year_month=${year_month}`,
      { responseType: "blob" }
    );
    return response;
  } catch (error) {
    return;
  }
};
// 주간 수익율 pdf download api
const getWeekProfitPdfApi = async (year_week) => {
  try {
    const response = await API.get(
      `weekly-profit/pdf?year_week=${year_week}`
    );
    return response;
  } catch (error) {
    return;
  }
};
// 주간 수익율 excel download api
const getWeekProfitExcelApi = async (year_week) => {
  try {
    const response = await API.get(
      `weekly-profit/excel-xls?year_week=${year_week}`,
      { responseType: "blob" }
    );
    return response;
  } catch (error) {
    return;
  }
};
// 일일 결산 조회 api
const getDailyProfitApi = async (info, page) => {
  try {
    if (info.start_date == "" && info.end_date == "") {
      const response = await API.get(
        `daily-profit-report?page=${page}&per_page=${info.per_page}&exchange=${info.exchange}`
      );
      return response.data;
    } else {
      const response = await API.get(
        `daily-profit-report?page=${page}&per_page=${info.per_page}&exchange=${info.exchange}&start_date=${info.start_date}&end_date=${info.end_date}`
      );
      return response.data;
    }
  } catch (error) {
    return;
  }
};
// 거래소별 주간 수익률 조회 api
const getWeekExchangeApi = async (exchange) => {
  try {
    const response = await API.get(`weekly-profit-report?exchange=${exchange}`);
    return response.data;
  } catch (error) {
    return;
  }
};
// 월말 결산 조회 리트리 api
const getMonthLogApi = async (info, page) => {
  try {
    if (whileFetching) abortController.abort();
    abortController = new AbortController();
    const signal = abortController.signal;
    whileFetching = true;
    const response = await API.get(
      `month-profits?page=${page}&size=${info.size}&date=${info.date}&profit_type=${info.type}`
    ,{ signal });
    whileFetching = false;
    return response.data;
  } catch (error) {
    return;
  }
};
// 월말 결산 & 주간 결산 조회 api
const getUserProfitApi = async (info, page) => {
  try {
    const response = await API.get(
      `user-profits?page=${page}&size=${info.size}&retri_id=${info.retri_id}&date=${info.date}&exc=${info.exchange}&profit_type=${info.type}`
    );
    return response.data;
  } catch (error) {
    return;
  }
};
export default {
  getProfitPdfApi,
  getProfitExcelApi,
  getWeekProfitPdfApi,
  getWeekProfitExcelApi,
  getMonthLogApi,
  getUserProfitApi,
  getProfitMonthApi,
  getDailyProfitApi,
  getWeekExchangeApi,
};
