import { createStore } from "vuex";
import dashboard from "./dashboard"
import referral from "./referral";
import admin from "./admin";
import manage from "./manage";
import connect from "./connect";
import closing from "./closing";

const store = createStore({
  modules: {
    dashboard,
    referral,
    admin,
    manage,
    connect,
    closing
  },
});

export default store;
