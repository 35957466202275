<template>
  <div class="modal_overlay" @click.self="closeModal">
    <div class="modal_pop_small">
      <div class="smallPop popup_block">
        <div class="pop_title">
          <p>ReTri Referral Admin</p>
        </div>
        <div class="con_wrap">
          <p>삭제하시겠습니까?</p>
        </div>
        <div class="btn_bottom">
          <button class="btn_gray" @click="deleteData()">확인</button>
          <button class="btn_gray" @click="closeModal()">취소</button>
        </div>
      </div>
    </div>
  </div>
  <ModalMsg :msg="msg" :callbackFn = closeModal :callbackStatus ="callbackStatus" v-if="modalState" />
</template>
<script setup>
import ModalMsg from "@/components/modal/ModalMsg.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  admin: { type: String },
});
const modalState = computed(() => {
  return store.state.admin.modalState;
});
const fetchStatus = computed(() => {
  return store.state.admin.fetchStatus;
});
let msg = ref("");
let callbackStatus = 1;
const deleteData = async () => {
  store.commit("admin/setAdminId", props.admin);
  await store.dispatch("admin/deleteAdmin");
  if (fetchStatus.value == 200) {
    msg.value = "성공적으로 삭제하였습니다.";
    await store.dispatch("admin/getAdmin");
  } else {
    msg.value = "삭제에 실패하였습니다. 다시 시도해주세요.";
  }
  store.commit("admin/changeModalState", true);
};
const closeModal = async () => {
  store.commit("admin/changeAdminModal", false);
  store.commit("admin/setFetchStatus", null);
};
</script>
