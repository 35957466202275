<template>
  <aside class="layout_menu" :class="{ active: isSideMenu }">
    <div class="logo_box">
      <router-link to="/dash-board" @click="changeSideMenu">
        <i class="logo">Referral Admin</i>
      </router-link>
      <button type="button" class="menu_close" @click="changeSideMenu"></button>
    </div>
    <ul class="menu_inner">
      <li
        class="menu_item"
        :class="{ active: mainPath === 'connect' }"
        @click="changeSideMenu"
      >
        <router-link to="/connect">
          <i class="ti ti-settings"></i>
          <span>연동관리</span></router-link
        >
      </li>
      <li
        class="menu_item"
        :class="{ active: mainPath === 'referral' }"
        @click="changeSideMenu"
      >
        <router-link to="/referral/payback">
          <i class="ti ti-tool"></i>
          <span>레퍼럴관리</span></router-link
        >
      </li>
      <li
        class="menu_item"
        :class="{ active: mainPath === 'closing' }"
        @click="changeSideMenu"
      >
        <router-link to="/closing/daily">
          <i class="ti ti-id"></i>
          <span>결산관리</span></router-link
        >
      </li>
      <li
        class="menu_item"
        :class="{ active: mainPath === 'admin' }"
        @click="changeSideMenu"
        v-if="role == 1"
      >
        <router-link to="/admin">
          <i class="ti ti-smart-home"></i>
          <span>관리자 정보</span>
        </router-link>
      </li>
    </ul>
  </aside>
  <div class="layout_overlay"></div>
</template>

<script setup>
import { getItemWithExpireTime } from "@/utils/common";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const route = useRoute();
const store = useStore();
const isSideMenu = computed(() => {
  return store.state.admin.isSideMenu;
});
const mainPath = computed(() => {
  return route.path.split("/")[1];
});
const role = computed(() => {
  return getItemWithExpireTime("userInfoObj")?.role;
});
const changeSideMenu = () => {
  store.commit("admin/setSideMenu", false);
};
</script>

<style scoped></style>
