import api from "@/api/apiManage";

export default {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      reqMemberInfo: {
        exchange: 0,
        size: 5,
      },
      page: 1,
      //res
      dashboardInfo: {},
      totalPages: 0,
      memberList: [],
    };
  },
  mutations: {
    setPage(state, str) {
      if (str == "prev") {
        state.page--;
      } else if (str == "next") {
        state.page++;
      } else {
        state.page = 1;
      }
    },
  },
  actions: {
    async getDashBoard(context) {
      try {
        let response = await api.getDashBoardApi();
        context.state.dashboardInfo = response.results;
      } catch (error) {
        return;
      }
    },
    async getTotalMember(context) {
      try {
        let response = await api.getTotalMemberApi(
          context.state.reqMemberInfo,
          context.state.page
        );
        // console.log(response);
        context.state.memberList = response.results;
        context.state.totalPages = response.totalPages;
      } catch (error) {
        return;
      }
    },
    async getNewMember(context) {
      try {
        let response = await api.getNewMemberApi(
          context.state.reqMemberInfo,
          context.state.page
        );
        context.state.memberList = response.results;
        context.state.totalPages = response.totalPages;
      } catch (error) {
        return;
      }
    },
  },
};
