import api from "@/api/apiClosing";
import { autoLeftPad } from "@/utils/common";

export default {
  namespaced: true,
  state() {
    return {
      reqMonthInfo: {},
      reqDailyInfo: {},
      reqMonthLogInfo: {},
      isLoading: false,
      page: 1,
      logWeekDate: "",
      logMonthDate: "",
      //res
      totalPages: 0,
      monthCountInfo: {},
      weekCountInfo : {},
      dailyCountInfo: {},
      fileExcel: "",
      filePdf: "",
      monthLogList: [],
      monthProfitList: [],
      dailyProfitList: [],
    };
  },
  mutations: {
    setDailyInfo(state, payload) {
      state.reqDailyInfo = {
        per_page: 10,
        exchange: payload.exchange,
        start_date: payload.start_date,
        end_date: payload.end_date,
      };
    },
    setMonthInfo(state, payload) {
      state.reqMonthInfo = {
        type : payload.type,
        size: 10,
        date: payload.date,
        retri_id: payload.retri_id,
        exchange: payload.exchange,
      };
    },
    setPage(state, str) {
      if (str == "prev") {
        state.page--;
      } else if (str == "next") {
        state.page++;
      } else {
        state.page = 1;
      }
    },
    setLogWeekDate(state, payload) {
      state.logWeekDate = payload;
    },
    setLogMonthDate(state, payload) {
      state.logMonthDate = payload;
    },
    setMonthLogInfo(state, payload) {
      state.reqMonthLogInfo = {
        size: 10,
        date: payload.date,
        type : payload.type
      };
    },
  },
  actions: {
    async getProfitMonth(context, exchange) {
      try {
        let date = new Date();
        let year_month = `${date.getFullYear()}-${autoLeftPad(
          date.getMonth() + 1,
          2
        )}`;
        let response = await api.getProfitMonthApi(exchange, year_month);
        context.state.monthCountInfo = response;
      } catch (error) {
        return;
      }
    },

    async getProfitPdf(context) {
      try {
        let response = await api.getProfitPdfApi(context.state.logMonthDate);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        context.state.filePdf = url;
      } catch (error) {
        return;
      }
    },
    async getProfitExcel(context) {
      try {
        let response = await api.getProfitExcelApi(context.state.logMonthDate);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        context.state.fileExcel = url;
      } catch (error) {
        return;
      }
    },
    async getWeekProfitPdf(context) {
      try {
        let response = await api.getWeekProfitPdfApi(context.state.logWeekDate);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        context.state.filePdf = url;
      } catch (error) {
        return;
      }
    },
    async getWeekProfitExcel(context) {
      try {
        let response = await api.getWeekProfitExcelApi(context.state.logWeekDate);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        context.state.fileExcel = url;
      } catch (error) {
        return;
      }
    },
    async getMonthLog(context) {
      try {
        context.state.totalPages = 1;
        let response = await api.getMonthLogApi(
          context.state.reqMonthLogInfo,
          context.state.page
        );
        context.state.monthLogList = response.result;
        context.state.totalPages = response.total_page;
      } catch (error) {
        return;
      }
    },
    async getUserProfit(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        let response = await api.getUserProfitApi(
          context.state.reqMonthInfo,
          context.state.page
        );
        context.state.monthProfitList = response.result;
        context.state.totalPages = response.total_page;
        context.state.isLoading = false;
        // console.log(context.state.reqMonthInfo);
        // console.log(context.state.totalPages);
        // console.log(response);
      } catch (error) {
        return;
      }
    },
    async getDailyProfit(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        let response = await api.getDailyProfitApi(
          context.state.reqDailyInfo,
          context.state.page
        );
        context.state.isLoading = false;
        if (response) {
          context.state.dailyProfitList = Object.entries(
            response.daily_profits
          );
          context.state.dailyCountInfo = {
            total_accumulated_profit: response.total_accumulated_profit,
            day_profit: response.day_profit,
          };
          context.state.totalPages = response.total_pages;
        } else {
          context.state.dailyProfitList = [];
        }
      } catch (error) {
        return;
      }
    },
    async getWeekExchange(context,exchange) {
      try {
        let response = await api.getWeekExchangeApi(
          exchange
        );
        context.state.weekCountInfo = response;
      } catch (error) {
        return;
      }
    },
  },
};


