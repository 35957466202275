<template>
  <div class="tabMenu_inner border-0 mt-0">
    <ul class="tab_link_box ctgy02">
      <li :class="{ active: exchange === 0 }" @click="changeTab(0)">ALL</li>
      <li :class="{ active: exchange === 1 }" @click="changeTab(1)">OKX</li>
      <li :class="{ active: exchange === 3 }" @click="changeTab(3)">Toobit</li>
      <li :class="{ active: exchange === 2 }" @click="changeTab(2)">BingX</li>
      <li :class="{ active: exchange === 4 }" @click="changeTab(4)">
        DeepCoin
      </li>
    </ul>
  </div>
  <div class="total_info diff mb-50">
    <div>
      <p class="fs-20">총 수익금</p>
      <p class="fs-30 text_blue">
        {{ formatNum(dailyCountInfo.total_accumulated_profit,4) }}$
      </p>
    </div>
    <div>
      <p class="fs-20">금일 수익금</p>
      <p class="fs-30 text_plus">
        {{ formatNum(dailyCountInfo.day_profit,4)}}$
      </p>
    </div>
  </div>
  <div class="card mb-20">
    <div class="card_header">
      <h5 class="card_title"><i class="ti ti-files mr-5"></i>일일결산</h5>
    </div>
    <div class="form_inner">
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">거래소</h5>
        <select
          class="form-select display-inline wp-15 mr-5"
          data-allow-clear="true"
          v-model="info.exchange"
        >
          <option value="0">All</option>
          <option value="1">OKX</option>
          <option value="3">Toobit</option>
          <option value="2">BingX</option>
          <option value="4">DeepCoin</option>
        </select>
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">일자</h5>
        <input
          type="date"
          class="form_control wp-20 mr-5 display-inline"
          v-model="info.start_date"
        />
        ~
        <input
          type="date"
          class="form_control wp-20 ml-5 display-inline"
          v-model="info.end_date"
        />
      </div>
      <div class="filter-btn">
        <button type="button" class="btn btn-primary mlr-5" @click="infoSearch">
          검색
        </button>
        <button
          @click="infoClear"
          type="button"
          class="btn btn-secondary mlr-5"
        >
          클리어
        </button>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="col-12 mb-20">
    <div class="card">
      <div class="table-responsive text-nowrap dataTables_wrapper">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>수익금</th>
              <th>발생날짜</th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-if="dailyProfitList.length == 0">
              <td colspan="7">데이터 내역이 없습니다.</td>
            </tr>
            <tr v-for="(data, i) in dailyProfitList" :key="i">
              <td>{{ formatNum( data[1],4) }}</td>
              <td>{{ data[0] }}</td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <td colspan="8" class="list_loading">
              <div class="loading_spinner">
                <div class="loading_circle"></div>
              </div>
            </td>
          </tbody>
        </table>
        <ThePaging
          v-if="totalPages > 1"
          :page="page"
          :changePage="changePage"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
import { formatNum } from "@/utils/common";
const store = useStore();
const dailyProfitList = computed(() => {
  return store.state.closing.dailyProfitList;
});
const dailyCountInfo = computed(() => {
  return store.state.closing.dailyCountInfo;
});
const page = computed(() => {
  return store.state.closing.page;
});
const totalPages = computed(() => {
  return store.state.closing.totalPages;
});
const isLoading = computed(() => {
  return store.state.closing.isLoading;
});
let exchange = ref(0);
let info = {
  exchange: 0,
  start_date: "",
  end_date: "",
};
const infoSearch = async () => {
  let reqInfo = {
    exchange: info.exchange ?? 0,
    start_date: info.start_date ?? "",
    end_date: info.end_date ?? "",
  };
  store.commit("closing/setPage", 1);
  store.commit("closing/setDailyInfo", reqInfo);
  await store.dispatch("closing/getDailyProfit");
};
const infoClear = async () => {
  info = {
    exchange: 0,
    start_date: "",
    end_date: "",
  };
  store.commit("closing/setDailyInfo", info);
  store.commit("closing/setPage", 1);
  await store.dispatch("closing/getDailyProfit");
};
const changePage = async (str) => {
  store.commit("closing/setPage", str);
  await store.dispatch("closing/getDailyProfit");
};
const changeTab = async (state) => {
  exchange.value = state;
  let reqInfo = {
    exchange: exchange.value ?? "",
    start_date: "",
    end_date: "",
  };
  store.commit("closing/setPage", 1);
  store.commit("closing/setDailyInfo", reqInfo);
  await store.dispatch("closing/getDailyProfit");
};
const createFn = async () => {
  let reqInfo = {
    exchange: 0,
    start_date: "",
    end_date: "",
  };
  store.commit("closing/setPage", 1);
  store.commit("closing/setDailyInfo", reqInfo);
  await store.dispatch("closing/getDailyProfit");
};
createFn();
</script>
