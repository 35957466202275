<template>
  <div class="content_wrapper">
    <div class="pl-10 pr-10 mb-20">
      <div class="dash_title">
        <h1>DashBoard</h1>
        <span>[REPORT] {{ reportDate }}</span>
      </div>
      <div class="dash_board_wrap">
        <div class="col-md-6 col-12 mb-20 pr-10 pl-10">
          <div class="dash_box" @click="modalOpen(1)">
            <i class="ti ti-user"></i>
            <div class="dash_text_area">
              <h3>Total Member</h3>
              <p class="ml-20">{{ dashboardInfo.total_count }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 mb-20 pr-10 pl-10">
          <div class="dash_box" @click="modalOpen(3)">
            <i class="ti ti-coins"></i>
            <div class="dash_text_area">
              <h3>Total Profit</h3>
              <p class="ml-20">
                {{ formatNum(dashboardInfo.total_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box" @click="modalOpen(2)">
            <i class="ti ti-user-plus"></i>
            <div class="dash_text_area">
              <h3>New Member</h3>
              <p class="ml-20">{{ dashboardInfo.total_today }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box" @click="modalOpen(4)">
            <i class="ti ti-currency-dollar ti-xl"></i>
            <div class="dash_text_area">
              <h3>This Week Profit</h3>
              <p class="ml-20">
                {{ formatNum(dashboardInfo.week_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box" @click="modalOpen(4)">
            <i class="ti ti-currency-dollar ti-xl"></i>
            <div class="dash_text_area">
              <h3>This Month Profit</h3>
              <p class="ml-20">
                {{ formatNum(dashboardInfo.month_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="col-12 mt-20 mb-10 pr-10 pl-10">
        ※ Toobit, BingX 는 2일 전 데이터 / DeepCoin, OKX 는 1일 전 데이터입니다.
      </p>
      <div class="dash-chart">
        <TheBar v-if="!isLoading" :exchangeArr="exchangeArr"></TheBar>
      </div>
    </div>
  </div>
  <ModalList
    :status="status"
    v-if="modalState && (status == 1 || status == 2)"
  />
  <ModalProfit
    :status="status"
    v-if="modalState && (status == 3 || status == 4)"
  />
</template>
<script setup>
import { autoLeftPad, formatNum } from "@/utils/common";
import ModalList from "./modal/ModalList.vue";
import ModalProfit from "./modal/ModalProfit.vue";
import TheBar from "./public/TheBar.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const dashboardInfo = computed(() => {
  return store.state.manage.dashboardInfo;
});
const monthCountInfo = computed(() => {
  return store.state.closing.monthCountInfo;
});
const weekCountInfo = computed(() => {
  return store.state.closing.weekCountInfo;
});
const dailyCountInfo = computed(() => {
  return store.state.closing.dailyCountInfo;
});
let exchangeArr = ref([
  { name: "OKX" },
  { name: "Toobit" },
  { name: "BingX" },
  { name: "Deepcoin" },
]);

const modalState = computed(() => {
  return store.state.admin.modalState;
});
let isLoading = ref(false);
let status = ref(null);
const date = new Date();
const reportDate = `${date.getFullYear()}
.${autoLeftPad(date.getMonth() + 1, 2)}
.${autoLeftPad(date.getDate(), 2)} 
${date.getHours()}:00`;

const modalOpen = (state) => {
  status.value = state;
  store.commit("admin/changeModalState", true);
};
const createdFn = async () => {
  isLoading.value = true;
  await store.dispatch("manage/getDashBoard");
  let info = {
    exchange: 0,
    start_date: "",
    end_date: "",
  };
  for (const obj of exchangeArr.value) {
    switch (obj.name) {
      case "OKX":
        info.exchange = 1;
        await store.dispatch("closing/getProfitMonth", 1); //월간
        await store.dispatch("closing/getWeekExchange", 1); //주간
        break;
      case "Toobit":
        info.exchange = 3;
        await store.dispatch("closing/getProfitMonth", 3);
        await store.dispatch("closing/getWeekExchange", 3);
        break;
      case "BingX":
        info.exchange = 2;
        await store.dispatch("closing/getProfitMonth", 2);
        await store.dispatch("closing/getWeekExchange", 2);
        break;
      case "Deepcoin":
        info.exchange = 4;
        await store.dispatch("closing/getProfitMonth", 4);
        await store.dispatch("closing/getWeekExchange", 4);
        break;
    }
    store.commit("closing/setDailyInfo", info);
    await store.dispatch("closing/getDailyProfit");
    obj.monthly_profit = monthCountInfo.value?.monthly_profit ?? "-";
    obj.monthly_total = monthCountInfo.value?.total_accumulated_profit ?? "-";
    obj.weekly_profit = weekCountInfo.value?.weekly_profit ?? "-";
    obj.daily_profit = dailyCountInfo.value?.day_profit ?? "-";
  }
  isLoading.value = false;
};
createdFn();
</script>
