<template>
  <div class="content_wrapper">
    <div class="page_title mb-0">
      <i class="ti tf-icons ti-id"></i>
      <h4 class="title">결산관리</h4>
      <div class="tabMenu_inner border-0">
        <div class="tab_link_box">
          <router-link to="/closing/daily" :class="{ active: subPath === 'daily' }"
            >일일 결산</router-link
          >
          <router-link to="/closing/week" :class="{ active: subPath === 'week' }"
            >주간 결산</router-link
          >
          <router-link
            to="/closing/month"
            :class="{ active: subPath === 'month' }"
            >월말 결산</router-link
          >
          <router-link to="/closing/log" :class="{ active: subPath === 'log' }"
            >결산 내역</router-link
          >
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script setup>
import { useRoute } from "vue-router";
import { computed } from "vue";
const route = useRoute();
const subPath = computed(() => {
  return route.path.split("/")[2];
});
</script>
