<template>
  <div class="layout_wrapper bg_page_1">
    <!--로그인 Box:start-->
    <div class="login_inner">
      <div class="card">
        <h3 class="title">LOG IN</h3>
        <h6 class="subtitle">Referral 관리자 로그인</h6>
        <div class="login_icon">
          <i class="ti ti-lock fs-70"></i>
        </div>
        <div class="form_inner">
          <div class="col-12 mb-15">
            <label class="form_label" for="adminId">Admin ID</label>
            <div class="input_group">
              <input
                type="text"
                id="adminId"
                name="adminId"
                class="form_control"
                placeholder="admin ID"
                v-model="loginInfo.username"
              />
            </div>
            <div class="invalid_feedback">Admin ID를 입력하세요.</div>
          </div>
          <div class="col-12 mb-15">
            <label class="form_label" for="adminPw">Password</label>
            <div class="input_group">
              <i
                @click="togglePwd"
                :class="pwView ? 'pw_off' : 'pw_view'"
              ></i>
              <input
                type="password"
                id="adminPw"
                name="adminPw"
                class="form_control"
                placeholder="············"
                autoComplete="off"
                v-model="loginInfo.password"
                @keyup.enter="goLogin"
              />
            </div>
            <div class="invalid_feedback">패스워드를 입력하세요.</div>
          </div>
          <div class="col-12 mb-15 text-center">
            <button
              type="button"
              class="btn login-btn"
              @click="goLogin"
              @keyup.enter="goLogin"
            >
              로그인
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--로그인 Box:End-->
  </div>
  <ModalMsg :msg="msg" v-if="modalState"/>
</template>
<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ModalMsg from "@/components/modal/ModalMsg.vue";
import { getItemWithExpireTime } from "@/utils/common";
const store = useStore();
const router = useRouter();
let loginInfo = {
  username: "",
  password: "",
};
let msg = "";
let pwView = ref(false);

const modalState = computed(()=>{
  return store.state.admin.modalState;
});
const loginStatus = computed(()=>{
  return store.state.admin.loginStatus;
});
const goLogin = async () => {
  try {
    // console.log(loginInfo);
    store.commit("admin/setLoginInfo",loginInfo);
    await store.dispatch("admin/userLogin");
    const token = getItemWithExpireTime("userInfoObj")?.token;

    if(token){
      store.commit("admin/updateLoginStatus",true);
      router.push("/dash-board");
    } else{
      msg = "로그인 정보를 확인해주세요.";
      store.commit("admin/changeModalState",true);
    }
  } catch (error) {
    return;
  }
};

// // 비밀번호 숨김 버튼 함수
const togglePwd = (event) => {
  const input = event.target.nextElementSibling;
  if (input.type === "password") {
    input.type = "text";
    pwView.value = true;
  } else {
    input.type = "password";
    pwView.value = false;
  }
};

if(loginStatus.value){
  msg = "로그아웃 되셨습니다.";
  store.commit('admin/updateLoginStatus',false);
  store.commit("admin/changeModalState",true);
  window.sessionStorage.clear();
}
</script>
<style>
.layout_wrapper .bg_page_1 {
  /* background: linear-gradient(to bottom right, #aa4bf8, #4fa0fd); */
  background: linear-gradient(to bottom right, #36d1dc, #9b5be5);
}
</style>
