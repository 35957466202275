<template>
  <div class="modal_overlay" @click.self="closeModal">
    <div class="modal_pop_small">
      <div class="smallPop popup_block">
        <div class="pop_title">
          <p>ReTri Referral Admin</p>
        </div>
        <div class="con_wrap">
          <p>{{ props.msg }}</p>
        </div>
        <div class="btn_bottom">
          <button class="btn_gray" @click="closeModal">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  msg: { type: String },
  callbackFn: { type: Function },
  callbackStatus: { type: Number },
});
const closeModal = () => {
  if (props.callbackStatus == 1) {
    props.callbackFn && props.callbackFn();
  }
  store.commit("admin/changeModalState", false);
};
</script>

<style lang="scss" scoped></style>
