<template>
  <!-- <nav class="layout_nav"> -->
  <div class="nav_btn" @click="changeSideMenu">
    <span class="line line1"></span>
    <span class="line line2"></span>
    <span class="line line3"></span>
  </div>
  <div class="layout_top_gnb">
    <div class="admin_info" @click="isActive = !isActive">
      <i class="admin_user_face"></i>
      <p>{{ adminId }}</p>
    </div>
    <div :class="`admin_infobox ${isActive == true ? 'active' : ''}`">
      <router-link to="/admin" v-if="role == 1" @click="isActive = false"
        >관리자정보</router-link
      >
      <router-link to="/login" @click="isActive = false">로그아웃</router-link>
    </div>
  </div>
  <!-- </nav> -->
</template>

<script setup>
import { getItemWithExpireTime } from "@/utils/common";
import { computed, ref } from "vue";
import { useStore } from "vuex";
const store = useStore();
const isSideMenu = computed(() => {
  return store.state.admin.isSideMenu;
});
const adminId = computed(() => {
  return getItemWithExpireTime("userInfoObj")?.adminId;
});
const role = computed(() => {
  return getItemWithExpireTime("userInfoObj")?.role;
});
let isActive = ref(false);
const changeSideMenu = () => {
  store.commit("admin/setSideMenu", !isSideMenu.value);
};
</script>

<style scoped></style>
